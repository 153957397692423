
import GeneratorSettingsRow from "@/components/settings/hardware/GeneratorSettingsRow.vue"
import DataCollection from "@/components/data/DataCollection.vue"
import HardwareService from "@/services/HardwareService"
import { computed, nextTick, onMounted, onUnmounted, ref, watch, defineComponent } from "vue"
import ResultSet from "@/models/ResultSet"
import Column from "primevue/column"
import DataTable from "primevue/datatable"
import Dropdown from "primevue/dropdown"
import { RouterLink, useRouter } from "vue-router"
import { state } from "@/utils/state"
import LinkLabel from "@/components/navigation/LinkLabel.vue"

export default defineComponent({
  name: "GeneratorSettingsCollection",
  components: { DataCollection, GeneratorSettingsRow, LinkLabel },
  props: {
    sortingRouteName: {
      type: String,
    },
    computer: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    let part = 0
    const loadedAllData = ref(false)
    const loadingPart = ref(false)
    const scrollComponent = ref()
    const service = new HardwareService()
    const collectionComponent = ref()
    const sortOptions = ref([
      { label: "Computer ↓", value: "computer.alias,asc" },
      { label: "Computer ↑", value: "computer.alias,desc" },
      { label: "Port ↓", value: "port,asc" },
      { label: "Port ↑", value: "port,desc" },
      { label: "ModulePort ↓", value: "modulePort,asc" },
      { label: "ModulePort ↑", value: "modulePort,desc" },
      { label: "Active ↓", value: "active,asc" },
      { label: "Active ↑", value: "active,desc" },
      { label: "Module Active ↓", value: "moduleActive,desc" },
      { label: "Module Active ↑", value: "moduleActive,asc" },
      { label: "Type ↓", value: "type,asc" },
      { label: "Type ↑", value: "type,desc" },
    ])
    const items = ref([])
    const loading = ref(true)
    const sort = ref("")
    const filter = ref("")
    const windowWidth = ref(window.innerWidth)
    const showSortOptions = computed(() => windowWidth.value < 960)
    const scrollable = computed(() => windowWidth.value > 960)

    loadPart(0, sort.value, filter.value)

    onMounted(() => {
      loading.value = false
      scrollComponent.value = document.querySelector(".p-datatable-wrapper")
      if (scrollComponent.value) {
        scrollComponent.value.addEventListener("scroll", checkVisible, { passive: true })
      }

      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener("scroll", checkVisible)
      }

      window.removeEventListener("resize", checkVisible)
    })

    watch(
      () => filter.value,
      () => {
        reload()
      }
    )

    watch(
      () => sort.value,
      () => {
        reload()
      }
    )

    function reload() {
      loadingPart.value = true
      part = 0
      loadedAllData.value = false
      items.value = []
      loadPart(part, sort.value, filter.value)
    }

    function checkVisible() {
      windowWidth.value = window.innerWidth
      if (loadedAllData.value) return
      if (loadingPart.value) return

      if (
        Math.ceil(scrollComponent.value.scrollTop * 1.3) >=
        scrollComponent.value.scrollHeight - scrollComponent.value.clientHeight
      ) {
        loadPart(part, sort.value, filter.value)
      }
    }

    function loadPart(part: Number, sort: any, filter: string) {
      loadingPart.value = true
      service
        .getGenerators(part, sort, filter, props.computer ? props.computer.id : null)
        .then((result: ResultSet<any>) => {
          onPartLoaded(part, sort, result)
        })
    }

    function onPartLoaded(loadedPart: Number, sort: any, result: ResultSet<any>) {
      loadingPart.value = false
      loadedAllData.value = !result.moreToCome

      if (result.rows.length == 0) {
        return
      }

      part++
      result.rows.map(function (element) {
        let item = { selected: false, data: element }
        items.value.push(item)
      })

      nextTick().then(() => {
        checkVisible()
      })
    }

    function sortColumn(event: any) {
      if (event.value) {
        sort.value = event.value
      } else {
        sort.value = event.sortField + "," + (event.sortOrder == 1 ? "desc" : "asc")
      }
    }

    const router = useRouter()
    const goToOrg = function (org: any) {
      router.push({ name: "organization", params: { organization_id: org.id } })
    }

    return {
      loadPart,
      collectionComponent,
      sortOptions,
      items,
      loading,
      sortColumn,
      sort,
      showSortOptions,
      scrollable,
      goToOrg,
      state,
    }
  },
})
